/**
 * Header Styles
 */

 @mixin sticky-header {
  margin: 0;
  padding: 0;
  list-style: none;
}

#page-header {

	font-family: $font-family-sans-serif;
	box-shadow: 0 2px 10px rgba(0,0,0,.5);
	background: $header-color;
	transition: all .1s linear;
	position: sticky;
	font-size: 100%;
	z-index: 300;
	width: 100%;
	clear: both;
	top: 0;
	left: 0;

	@include breakpoint(tablet) {
		position: relative;
		padding: 22px 0 0 0;
		box-shadow: none;
	}

	&.sticky {
		@include breakpoint(tablet) {
			.container {
				visibility: hidden;
			}

			.sticky-container {
				visibility: visible;
				display: block;
				top: 0;
			}
		}
	}

	a {
		color: $header-link-color;

		&:focus,
		&:hover {
			color: $link-hover-color;
		}
	}

	.sticky-container {
		box-shadow: 0 2px 10px rgba(0,0,0,.5);
		transition: all .3s linear;
		background: $header-color;
		padding: 10px 0;
		position: fixed;
		display: none;
		width: 100%;
		margin: 0;
		left: 0;
		top: 0;

		.inside {
			border-top: none;
			padding-top: 0;

			@include breakpoint(all) {
				align-items: center;
				display: flex;
			}
		}

		.logo {
			max-width: 25%;
			float: left;
			margin: 0;

			a {
				line-height: 32px;
			}

			.logo-img {
				height: 32px;
				width: 100px;
			}

			img {
				height: 32px;
				width: auto;
			}
		}

		.social-nav {
			position: relative;
			max-width: 25%;
			float: right;
			padding: 0;
			margin: 0;
			left: 0;
			top: 0;

			@include breakpoint(all) {
				margin: 0 0 0 auto;
			}
		}

		.navigation {
			margin: 5px 0 0 25px;
			border: none;
			float: left;
			width: 50%;
			padding: 0;

			ul {
				align-items: center;
			}

			ul li {
				flex: 0 0 auto;
			}
		}

		.navigation .dropdown-menu{
			min-width: 150px;
			right: auto;
			left: 0;

			&.edge {
				right: 0;
				left: auto;
			}
		}

		.nav-button {
			float: right;
			height: 40px;
			width: 40px;
			font-size: 28px;
			line-height: 40px;
		}
	}

	.inside {
		transition: all .3s ease;

		@include breakpoint(all) {
			align-items: center;
			display: flex;
		}
		@include breakpoint(tablet) {
			border-top: 1px solid $brand-border;
			padding: 17px 0 0 0;
			display: block;
		}
	}

	.nav-button {
		transition: all .3s ease;
		text-decoration: none;
		position: relative;
		display: block;
		font-size: 32px;
		padding: 0 6px;
		float: right;

		@include transition(all .3s ease);
		@include icon($fa-var-bars);

		@include breakpoint(tablet) {
			display: none;
		}
	}

	.logo {
		transition: all .3s ease;
		float: left;
		margin: 0;

		a {
			line-height: 32px;
		}

		img {
			max-width: 100%;
			max-height: 32px;
		}

		a {
			text-decoration: none;
			display: inline-block;
			margin: 0 auto;
			padding: 0;
		}

		h1,h2,h3,h4,h5,h6 {
			font-size: 1em;
			padding: 0;
			margin: 0;
		}

		.logo-img {
			background-image: url("#{$image-path}/logo-themith.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			height: 52px;
			width: 150px;

			html.supports-svg & {
				background-image: url("#{$image-path}/logo-themith.svg");
			}
		}


		@include breakpoint(tablet) {
			margin: -5px auto 0 auto;
			text-align: center;
			max-width: none;
			float: none;

			.logo-img {
				width: 260px;
				height: 65px;
			}

			img {
				height: auto;
			}
		}
	}

	.social-nav {
		transition: all .05s linear;
		position: relative;
		white-space: nowrap;
		font-size: 22px;
		float: right;
		padding: 0;
		margin: 0;
		left: 0;
		top: 0;

		a {
			text-decoration: none;
			padding: 0 6px;
		}

		@include breakpoint(all) {
			margin: 0 0 0 auto;
		}

		@include breakpoint(tablet) {
			position: absolute;
			margin: 0 5% 10px 0;
			text-align: right;
			max-width: none;
			float: none;
			left: auto;
			top: 60px;
			right: 0;
		}
	}

	.navigation {
		transition: all .05s linear;
		margin: 5px 0 0 25px;
		font-size: 1em;
		display: none;
		border: none;
		float: left;
		width: 50%;
		padding: 0;

		@include clearfix();

		> ul {
			align-items: center;
			list-style: none;
			display: flex;
			padding: 0;
			margin: 0;
		}

		> ul > li {
			position: relative;
			display: inline-block;
			flex: 0 0 auto;
			padding: 0;
			margin: 0;

			&.active .dropdown-menu {
				transform: scale(1);
				visibility: visible;
				opacity: 1;
			}
		}

		> ul > li > a {
			text-decoration: none;
			text-align: center;
			margin-bottom: -2px;
			padding: 10px 10px;
			line-height: 1em;
			display: block;
		}

		@include breakpoint(tablet) {
			border-top: 1px solid $brand-border;
			margin: 17px 0 0 0;
			display: block;
			padding: 0;
			width: 100%;

			> ul > li {
				flex: 1 1 0;
			}
		}
	}

	.navigation .dropdown-menu{
		transition: all .05s linear;
		box-sizing: border-box;
		transform-origin: top;
		background: #555557;
		visibility: hidden;
		position: absolute;
		transform: scale(0);
		padding: 5px 0;
		width: 100%;
		z-index: 10;
		opacity: 0;
		top: 100%;
		left: 30px;
		right: 30px;

		li {
			position: relative;
			display: block;
			float: none;
			width: 100%;
			padding: 0;
			margin: 0;
		}

		a {
			text-decoration: none;
			text-transform: none;
			padding: 10px 15px;
			text-align: left;
			display: block;
			color: #fff;
			margin: 0;

			&:focus,
			&:hover {
				color: $link-hover-color;
			}
		}

		ul {
			position: absolute;
			bottom: auto;
			right: auto;
			left: 100%;
			margin: 0;
			top: 0;
		}
	}

	#post-type-post & {
		background-color: #000;
		color: #fff;

		a {
			color: #fff;

			&:focus,
			&:hover {
				color: $link-hover-color;
			}
		}

		.navigation,
		.inside {
			border-color: #fff;
		}

		.sticky-container {
			background-color: #000;
			color: #fff;
		}

		.logo .logo-img {
			background-image: url("#{$image-path}/logo-themith-white.png");

			html.supports-svg & {
				background-image: url("#{$image-path}/logo-themith-white.svg");
			}
		}
	}

}

/**
 * Form Styles
 *
 * 	_Global
 * 	_Validation
 * 	_layout
 *
 */



 /*
 * ================================
 *      _Global
 * ================================
 */

	.form-control,
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="color"],
	textarea,
	select {

		padding: $padding-base-vertical $padding-base-horizontal;
		border-radius: $input-border-radius;
		border: 2px solid $input-border;
		font-family: $font-family-base;
		background-clip: padding-box;
		background-color: $input-bg;
		font-size: $font-size-base;
		padding: .375rem .75rem;
		background-image: none;
		color: $input-color;
		line-height: 1.5;
		display: block;
		width: 100%;

		@include transition(border-color .15s ease-in-out,box-shadow .15s ease-in-out);
		@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
		@include placeholder;

		&::-ms-expand {
			background-color: transparent;
			border: 0;
		}

		&[disabled],
		&[readonly],
		fieldset[disabled] & {
			background-color: $input-bg-disabled;
			border-color: #d5dbdb;
			color: #d5dbdb;
			opacity: 0.7;
		}

		&[disabled],
		fieldset[disabled] & {
			cursor: not-allowed;
		}

		&:invalid {
			background: $input-bg;
		}

		&:hover{
			border-color: $input-border-active;
			outline: 0;
		}

		&:focus{
			@include box-shadow(0 0 0 .25rem rgba(13,110,253,.25));
			border-color: lighten($input-border-active, 5%);
			background-color: $input-bg-active;
			outline: 0;
		}

		&.has-icon {
			padding-right: 30px;
		}

		&.small {
			max-width: 100px;
			width: 25%;
		}

		&.medium {
			max-width: 200px;
			width: 50%;
		}

		&.large {
			max-width: 300px;
			width: 75%;
		}

		&.error,
		&.has-error {
			border-color: $input-error;
			color: $input-error;
			font-weight: bold;
			cursor: auto;
		}
	}

	label {
		display: inline-block;
		margin-bottom: 5px;
		font-weight: bold;
		font-size: 1.05em;
		max-width: 100%;

		&.error,
		&.has-error {
			color: $input-error;
		}
	}

	fieldset {
		min-width: 0;
		padding: 0;
		margin: 0;
		border: 0;

		& + fieldset {
			margin-top: em(50px);
		}
	}

	legend {
		font-size: ($font-size-base * 1.5);
		line-height: inherit;
		display: block;
		width: 100%;
		padding: 0;
		border: 0;
	}

	form ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	form ul li {
		padding: 0;
		margin: 0;

		& + li {
			margin-top: em(18px);
		}
	}

	textarea {
		min-height: em(125px);
		resize: vertical;
		height: auto;
	}

	select {
		background: none;
		padding: 9px 1%;
		width: 100%;
	}

	input[type="search"] {
		@include box-sizing(border-box);
		-webkit-appearance: none;
	}

	input[type="file"] {
		display: block;
	}

	input[type="submit"],
	input[type="button"],
	button {
		@extend .btn;

		&:hover,
		&:focus {
			@extend .btn:hover;
		}
	}

	input[type="range"] {
		display: block;
		width: 100%;
	}

	select[multiple],
	select[size] {
		height: auto;
	}

	input[type="radio"],
	input[type="checkbox"] {
		line-height: normal;
		margin: 4px 0 0;
	}

	input[type="radio"] + label,
	input[type="checkbox"] + label {
		display: inline-block;
	}

	.radio,
	.checkbox {
		margin-bottom: 10px;
		position: relative;
		margin-top: 10px;
		display: block;

		label {
			font-weight: normal;
			padding-left: 20px;
			margin-bottom: 0;
			cursor: pointer;
		}
	}

	.radio input[type="radio"],
	.radio-inline input[type="radio"],
	.checkbox input[type="checkbox"],
	.checkbox-inline input[type="checkbox"] {
		position: absolute;
		margin-left: -20px;
	}

	.radio + .radio,
	.checkbox + .checkbox {
		margin-top: -5px;
	}

	.radio-inline,
	.checkbox-inline {
		vertical-align: middle;
		display: inline-block;
		font-weight: normal;
		position: relative;
		padding-left: 20px;
		margin-bottom: 0;
		cursor: pointer;
	}

	.radio-inline + .radio-inline,
	.checkbox-inline + .checkbox-inline {
		margin-left: 10px;
		margin-top: 0;
	}

	// Form feedback
	.form-control-feedback {
		height: $input-height-base;
		width: $input-height-base;
		pointer-events: none;
		text-align: center;
		position: absolute;
		display: block;
		z-index: 2;
		right: 0;
		top: 0;
	}

	// help text
	.help-block,
	.help,
	.hint {
		color: lighten($text-color, 25%);
		margin-bottom: 10px;
		font-size: .75em;
		margin-top: 5px;
		display: block;
	}

	// instructions
	.form-instructions {
		padding: em(15px) 0;
		text-align: right;

		p,
		h1 {
			padding: 0;
			margin: 0;
		}
	}

	.search-form {
		ul {
			align-items: center;
			justify-content: space-between;
			display: flex;

			li {
				flex: 1 1 0;
				margin: 0;
				padding: 0;
			}

			li + li {
				flex-grow: 0;
				margin-left: auto;
				padding-left: 25px;
			}
		}
	}

	// icon
	.input-icon {
		position: relative;
	}

	.form-icon {
		position: absolute;
		color: $input-border;
		text-align: center;
		line-height: 41px;
		overflow: hidden;
		height: 38px;
		width: 33px;
		right: 2px;
		top: 2px;

		@include breakpoint(tablet) {
			line-height: 39px;
			height: 38px;
		}
	}

	body .gform_wrapper .gform_body .gform_fields .gfield input[type="text"], body .gform_wrapper .gform_body .gform_fields .gfield input[type="email"], body .gform_wrapper .gform_body .gform_fields .gfield input[type="url"], body .gform_wrapper .gform_body .gform_fields .gfield input[type="tel"], body .gform_wrapper .gform_body .gform_fields .gfield input[type="password"], body .gform_wrapper .gform_body .gform_fields .gfield select, body .gform_wrapper .gform_body .gform_fields .gfield textarea {
		background-color: #ccc;
		color: #000;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
	}
/**
 * Site map
 */

#page-body .site-map {
	margin: 0 auto;
	max-width: 70%;

	@include breakpoint(tablet) {
		max-width: 95%;
	}

	h2 {
		border-bottom: 1px solid $brand-secondary;
		padding: 0 0 5px 0;
		margin: 0 0 15px 0;
		font-size: 1.6em;
	}

	ul {
		list-style: none;
		font-size: 1.3em;
		padding: 0;
		margin: 0;
	}

	a {
		padding: 6px 10px;
		display: block;
	}

	a:hover,
	a:focus {
		background: $brand-secondary;
		text-decoration: none;
		color: #fff;
	}
}
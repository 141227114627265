/**
 * Homepage Styles
 */

#homepage {


}

.coming-soon h2 {
	font-family: $font-family-sans-serif;
}
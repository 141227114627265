

.wp-block-quote {
    border-bottom: 1px solid $brand-border;
    border-top: 1px solid $brand-border;
    box-sizing: border-box;
    font-size: 1.4em;
    padding: 25px;

    &.is-style-pull-left-quote {
        margin-top: 15px;
        float: right;
        width: 50%;
    }

    &.is-style-pull-right-quote {
        margin-top: 15px;
        float: right;
        width: 50%;
    }
}

.wp-block-image {
    .alignright,
    .alignleft {
        margin-bottom: 0;
    }

    figcaption {
        text-align: right;
        color: $brand-gray;
        margin-bottom: 0;
        font-size: .9em;
        font-style: italic;
    }
}
/**
 *  Gravity form styles
 */

#page .gform_body,
#page .gform_wrapper {
    .gfield_label {
        font-weight: 400;
        margin: 0;
    }

    .ginput_container {
        input {
            border-radius: inherit !important;
        }
    }

    .form-control.medium,
    input[type=color].medium,
    input[type=date].medium,
    input[type=datetime-local].medium,
    input[type=datetime].medium,
    input[type=email].medium,
    input[type=month].medium,
    input[type=number].medium,
    input[type=password].medium,
    input[type=search].medium,
    input[type=tel].medium,
    input[type=text].medium,
    input[type=time].medium,
    input[type=url].medium,
    input[type=week].medium,
    select.medium,
    textarea.medium {
        max-width: none;
        border-radius: inherit;
    }

    .top_label input.medium,
    .top_label select.medium {
        width: 50%;
    }

    // Errors
    div.validation_error {
        font-family: $font-family-sans-serif;
        line-height: 1.5em;
        border: none;
        padding: 0;
        margin: 0;
    }

    li.gfield.gfield_error,
    li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
    }

    li.gfield.gfield_error.gfield_contains_required label.gfield_label,
    li.gfield.gfield_error.gfield_contains_required div.ginput_container {
        margin: 0;
    }

    li.gfield.gfield_error.gfield_contains_required div.gfield_description {
        font-size: $font-family-sans-serif;
        padding: 5px 0 0 0;
        text-align: right;
        margin: 0;
    }
}
/**
 * Blog Styles
 */

/*
 * ================================
 * 	Post Single
 * ================================
 */

	.post-image {
		p {
			display: block;
			padding: 0;
			margin: 0;
			text-align: right;
			font-style: italic;
		}
	}

	.post-article {
		font-family: $font-family-secondary;
		position: relative;
		overflow: hidden;
		width: 100%;
		clear: both;

		.post-image {
			margin: 0 0 20px 0;

			a {
				display: block;
				margin: 2px;

				&:hover,
				&:focus {
					box-shadow: 0 0 0 .15rem $link-hover-color;
					border-radius: .2rem;
				}
			}

			.image-container {
				background: #eee;
				text-align: center;
			}

			img {
				width: auto;
				max-width: 100%;
			}

			p {
				display: block;
				padding: 0;
				margin: 0;
				text-align: right;
				font-style: italic;
			}
		}

		.post-heading {
			padding: 0 0 10px 0;
			position: relative;
			overflow: hidden;
			display: block;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: 2em;
				border: none;
				padding: 0;
				margin: 0;
			}

			p {
				line-height: 1.2em;
				font-size: .9em;
				padding: 0;
				margin: 0;
			}
		}

		.post-category {
			font-family: $font-family-sans-serif;
			text-transform: uppercase;
			color: $brand-highlight;
			margin-top: 30px;
			font-size: .9em;

			.view-all {
				font-size: 1.1em;
			}

			a {
				color: $brand-highlight;
			}

			a + a {
				margin-left: 15px;
			}
		}

		.post-meta {
			margin: 0 0 20px 0;
		}

		.date {
			font-family: $font-family-sans-serif;
			color: $brand-gray;
			font-weight: 500;
			display: block;
			font-size: .9em;
			margin-top: 10px;

			strong {
				padding-right: 10px;
				color: $text-color;
			}
		}

		.more-link {
			position: relative;
			padding: 20px 0 0 0;
			font-size: .8em;
			float: right;
			margin: 0;

			a {
				padding: 8px 15px;
			}
		}

		.post-content {
			padding: 0;
			margin: 0;
		}

		.social-links {
			margin-top: 20px;

			.link {
				font-family: $font-family-sans-serif;
				text-transform: uppercase;
				vertical-align: middle;
				display: inline-block;
				text-align: center;
				border-radius: 50%;
				line-height: 30px;
				margin-right: 10px;
				font-size: .9em;
				color: #fff;
				height: 30px;
				width: 30px;

				&.alt {
					background: transparent;
					border-radius: none;
					width: auto;
				}

				.text {
					color: $text-color;
					padding: 0 5px;
				}

				&.facebook {
					background-color: #3e5b99;
				}

				&.twitter {
					background-color: #4da7de;
				}

				&.reddit {
					background-color: #e7491e;
				}

				&.email {
					background-color: #000000;
				}
			}
		}

		.post-single & .content {
			font-size: 1.25em;
			line-height: 1.5;
		}
	}

/*
  * ================================
 *	Post Index
 * ================================
 */

	.post-container.post-index {
		& + .post-container {
			padding-top: 35px;
		}

		.post-article {
			overflow: hidden;

			& + .post-article {
				padding-top: 45px;
			}

			.post-image {
				margin: 0;

				@include breakpoint(tablet) {
					float: left;
					width: 35%;
				}

				.image-container {
					@include breakpoint(tablet) {
						overflow: hidden;
						height: 185px;
					}
				}

				img {
					max-width: none;
					width: 100%;
				}
			}

			.post-heading {
				h1, h2, h3, h4, h5, h6 {
					font-size: 1.25em;
					line-height: 1.1;
				}

			 	a {
					color: $text-color;

					&:hover,
					&:focus {
						color: $link-hover-color;
						text-decoration: none;
					}
				}

				.post-category {
					margin-top: 0;
				}
			}

			.post-content {
				line-height: 1.2em;

				> a {
					text-decoration: none;
					color: $text-color;

					&:hover,
					&:focus {
						color: $link-hover-color;
					}
				}
			}

			.post-meta {
				margin: 0;

				.date {
					strong {
						display: block;
					}
				}
			}

			.post-category {
				padding-bottom: 5px;

				a {
					color: $brand-highlight;

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}

			@include breakpoint(tablet) {
				display: flex;

				&.featured {
					display: block;
				}

				.post-image {
					float: none;
					flex-shrink: 0;
					max-width: 255px;
					width: 48%;
				}

				.post-content {
					float: none;
					width: auto;
					flex-grow: 1;
					padding-left: 15px;
				}

				&.full .post-content {
					padding: 0;
					width: 100%;
					float: none;
				}
			}
		}

		.featured.post-article {
			& + .post-article {
				padding-top: 25px;
			}

			.post-image {
				margin: 0 0 10px 0;
				background: none;
				max-width: none;
				float: none;
				width: 100%;

				p {
					display: block;
					padding: 0;
					margin: 0;
					text-align: right;
					font-style: italic;
				}

				@include breakpoint(tablet) {
					max-height: 260px;
					height: auto;
				}
			}

			.post-content {
				float: none;
				width: 100%;
				padding: 0;
			}

			.post-heading {
				padding: 0 0 20px 0;

				.section-sidebar & {
					padding-bottom: 5px;
				}

				h1, h2, h3, h4, h5, h6 {
					.section-sidebar & {
						font-size: 1.25em;
					}

					.section-content & {
						font-size: 2.5em;
					}
				}
			}

			// Date
			.post-meta {
				.date {
					font-size: 1em;
					margin-top: 0;

					strong {
						display: inline-block;
					}
				}
			}
		}
	}

/*
  * ================================
 *	Post Index Podcasts
 * ================================
 */

 	.post-container.post-index.podcast {
		padding: 0 0 20px 0;
		overflow: visible;
		@include clearfix();

		@include breakpoint(tablet) {
			margin: -20px -7px 0 -7px;
		}

		.post-article {
			box-sizing: border-box;
			position: relative;
			padding: 20px 7px;
			display: block;
			clear: none;

			@include breakpoint(tablet) {
				height: 440px;
				float: left;
				width: 33%;
			}

			.post-image {
				box-sizing: border-box;
				position: relative;
				max-width: none;
				height: 200px;
				float: none;
				width: 100%;

				.image-container {
					background-repeat: no-repeat;
					background-position: center;
					background-color: #eee;
					background-size: cover;
					position: absolute;
					display: block;
					bottom: 2px;
					right: 2px;
					padding: 0;
					margin: 0;
					left: 2px;
					top: 2px;

					&:after {
						@include transition(all .1s linear);
						background: rgba(0,0,0,.5);
						position: absolute;
						visibility: hidden;
						content: "";
						height: 100%;
						width: 100%;
						opacity: 0;
						z-index: 1;
						bottom: 0;
						right: 0;
						left: 0;
						top: 0;
					}

					&:before {
						background: #25408f url("#{$image-path}/bg-podcast-thumbnail.png") no-repeat bottom left;
						background-size: contain;
						position: absolute;
						content: "";
						width: 90px;
						bottom: 0;
						left: 0;
						top: 0;
					}

					&:hover,
					&:focus {
						&:after {
							visibility: visible;
							opacity: 1;
						}
					}
				}
			}

			.post-content {
				margin: 5px 0 0 0;
				float: none;
				width: 100%;
				padding: 0;
			}

			.post-heading {
				padding: 0;
				margin: 0;

				h1,h2,h3,h4,h5,h6 {
					display: inline-block;
					text-overflow: ellipsis;
					line-height: 1em;
					overflow: hidden;
					font-size: 1.5em;
					line-height: 1em;
					max-height: 2em;
					padding: 0;
					margin: 0;
				}
			}

			.post-summary {
				margin-top: 15px;

				p {
					text-overflow: ellipsis;
					line-height: 1em;
					overflow: hidden;
					max-height: 3em;
					padding: 0;
					margin: 0;
				}
			}

			.post-meta {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

			.post-link {
				margin-top: 10px;
				.btn {
					// background-image: url("#{$image-path}/icon-listen.png");
					// background-repeat: no-repeat;
					// background-position: 0 50%;
					padding: 5px 18px 5px 18px;
					font-size: .8em;
				}
			}

			.post-category {
				padding-top: 0;
				margin-top: 0;
			}
		}
	}

/*
  * ================================
 *	Post Slider
 * ================================
 */

	.post-container.slider {
		.post-article {
			background: #000;
			overflow: hidden;
			clear: none;

			.post-image {
				position: relative;
				height: 250px;
				padding: 0;
				margin: 0;

				@include breakpoint(tablet) {
					float: left;
					width: 50%;
				}

				.image-container {
					background-position: center;
					background-size: cover;
					position: absolute;
					box-shadow: none;
					height: 100%;
					width: 100%;
					padding: 0;
					margin: 0;
					left: 0;
					top: 0;
				}
			}

			.post-content {
				color: #fff;
				height: 250px;

				@include breakpoint(tablet) {
					float: right;
					width: 50%;
				}

				a {
					color: #fff;
				}

				.post-heading {
					padding: 60px 50px 0 50px;
					overflow: visible;

					h1,h2,h3,h4,h5,h6 {
						line-height: 1.2;
					}

					@include breakpoint(tablet) {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 0 50px;
						height: 100%;
					}
				}
			}

			.post-category {
				color: $brand-gray;
				margin: 10px 0 0 0;
				padding: 0;

				a {
					color: $brand-gray;
				}
			}
		}

		.slick-arrow {
			transform: translate(0, -50%);
			background: transparent;
			position: absolute;
			color: $text-color;
			text-align: center;
			font-size: 22px;
			cursor: pointer;
			display: block;
			line-height: 0;
			height: 38px;
			border: none;
			width: 38px;
			padding: 0;
			top: 50%;

			&.slick-disabled {
				display: none;
				visibility: hidden;
				opacity: 0;
			}

			&.slick-next {
				@include icon($fa-var-chevron-right);
				left: 100%;
			}

			&.slick-prev {
				@include icon($fa-var-chevron-left);
				right: 100%;
			}
		}
	}

/*
* ================================
 *	Podcast Single
 * ================================
 */

	.post-container.post-single.podcast {
		.podcast-heading {
			background: $gray-lighter;
			margin-bottom: 15px;
			position: relative;
			overflow: hidden;
			height: 580px;

			.img-container {
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}

			.post-heading {
				background: rgba(0,0,0,.6);
				margin: 150px 0 0 0;
				padding: 45px 25px;
				max-width: 380px;
				color: #fff;
			}
		}

		.podcast-links {
			padding: 0 0 30px 0;

			a, span {
				font-family: $font-family-sans-serif;
				text-transform: uppercase;
				text-decoration: none;
				display: inline-block;
				padding-right: 20px;
				color: $text-color;
				font-weight: bold;
				font-size: .8em;
			}

			a:hover,
			a:focus {
				color: $link-hover-color;
			}
		}

		.podcast-content {
			.flex-row {
				display: flex;
				flex: 1 0 0;

				.col {
					max-width: 170px;
					margin: 10px 30px 0 0;
				}

				.col + .col {
					max-width: none;
				}
			}

			p:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}

		.podcast-player {
			background:#000000;
			margin-top: 40px;

			iframe {
				width: 100%;
			}
		}
	}

/*
 * ================================
 * 	Post Navigation
 * ================================
 */

 	// View all
	.post-nav-all {
		text-transform: uppercase;
		margin-bottom: 10px;
	}

 	// Single nav
	.nav-single {
		text-align: center;
		overflow: hidden;
		padding: 30px 0;
		clear: both;

		a {
			@extend .btn !optional;
			padding: 0 5px;
		}

		.btn {
			padding: 8px 10px;
		}
	}

	// Pagination
	.pagination {
		font-family: $font-family-sans-serif;
		padding: 20px 0 3px 0;
		text-align: center;
		margin: 30px 0 0 0;
		overflow: hidden;
		display: block;
		float: left;
		width: 100%;
		clear: both;

		span,
		a {
			background: lighten($brand-border, 35%);
			display: inline-block;
			color: $text-color;
			border-radius: 2px;
			padding: 6px 10px;
			margin: 0 2px;

			&:hover,
			&:focus{
				background: $text-color-dark;
				text-decoration: none;
				color: #fff;
			}

			&.current {
				background: $link-color;
				color: #fff;
			}
		}
	}

/*
 * ================================
 * 	Post Comments
 * ================================
 */

	#comments {
		padding: 10px 0 0 0;
		margin: 15px 0 0 0;

		ol,
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		.comment {
			border: 1px solid #e8e4e3;
			background: #f7f7f7;
			position: relative;
			margin: 0 0 15px 0;
			padding: 10px;
			@include box-shadow(0 0 6px -2px rgba(0,0,0,0.4));
		}

		.comment .comment-inside {
			border: 1px solid #e8e4e3;
			padding: 25px 20px;
			background: #fff;
		}

		.comment-notes {
			margin: 0 0 15px 0;
			font-style: italic;
			font-size: 16px;
			color: #777;
		}

		.children .comment.bypostauthor,
		.comment.bypostauthor,
		.comment.bypostauthor .comment-inside {
			background: #e7f1f3;
		}

		.children {
			margin: 10px 0 0 15px;
			position: relative;
			list-style: none;
			right: -30px;
		}

		.children .comment {
			background: #fff;
			@include box-shadow(0 0 12px -4px rgba(0,0,0,0.6));
		}

		.comment-meta {
			margin-bottom: 5px;
		}

		.comment-meta a {
			color: $text-color;
		}

		.comment-meta img {
			padding: 0 10px 0 0;
			float: left;
		}

		.comment-meta-intro {
			font-family: $font-family-secondary;
			padding: 2px 0 0 0;
			font-size: 22px;
		}

		.comment-meta-date {
			font-size: 14px;
		}

		.comment .reply {
			position: absolute;
			font-size: 12px;
			margin: 15px;
			padding: 0;
			right: 0;
			top: 0;
		}

		.comments-title  {
			font-family: $font-family-secondary;
			padding: 13px 0 25px 0;
			line-height: 1.1em;
			font-weight: bold;
			font-size: 27px;
			border: none;
		}

		#reply-title {
			font-family: $font-family-base;
			line-height: 1.1em;
			padding: 20px 0;
			font-size: 22px;
			border: none;
			margin: 0;
		}

		.comment-nav-below {
			border-top: 1px solid $text-color-dark;
			text-align: center;
			margin: 15px 0 0 0;
			padding: 15px 0;
		}

		.comment-nav-below a {
			@extend .btn !optional;
		}

		.comment-meta-moderation {
			padding: 10px 0;
		}

		.comment-awaiting-moderation {
			font-size: 12px;
			display: block;
		}
	}

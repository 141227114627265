/**
 * Footer Styles
 */

#page-footer {

/*
 * ================================
 * 	_Global
 * ================================
 */

	color: $footer-text-color;
	position: relative;
	overflow: hidden;
	font-size: .9em;

	a {
		color: $footer-link-color;

		&:hover,
		&:focus {
			color: $link-hover-color;
		}
	}

	p {
		padding: 0;
		margin: 0;
	}

	.section-row {
		background: $footer-color;
		overflow: hidden;
		padding: 18px 0;

		&:last-child {
			border-top: 1px solid $brand-border;
			padding: 10px 0;
		}

		.col {
			& + .col {
				margin-top: 40px;
			}
		}

		@include breakpoint(tablet) {
			.container {
				justify-content: space-between;
				display: flex;

				&.align-center {
					align-items: center;
				}

				.col {
					max-width: 40%;
					float: left;
					flex: 1 1 0;

					& + .col {
						margin-top: 0;
						margin-left: auto;
					}
				}
			}
		}
	}

	.navigation {
		font-family: $font-family-sans-serif;

		a {
			display: block;
			padding: 10px 0;
		}

		li + li {
			border-top: 1px solid $brand-border;
		}

		@include breakpoint(tablet) {
			padding: 0;

			li + li {
				border-top: none;
			}

			a {
				padding: 0;
			}
		}
	}

	.quote {
		text-align: center;
		margin-top: 2px;
		font-size: 1.2em;

		@include breakpoint(tablet) {
			text-align: left;
		}
	}

	.logo {
		text-align: center;
		margin: 0 auto;

		@include breakpoint(tablet) {
			text-align: right;
			margin-left: auto;
		}
	}

	.newsletter {
		.ginput_container {
			input {
				width: 100% !important;
			}
		}

		.gform_heading {
			margin: 0 0 10px 0;

			h1,h2,h3,h4,h5,h6 {
				font-size: 1.5em;
				font-weight: normal;
				font-family: $font-family-sans-serif;
			}
		}

		.gform_footer {
			margin-top: 10px;
			padding: 2px 0 5px 0;
		}

		.gform_title {
			margin: 0px;
		}

		.gform_body,
		.gform_wrapper {
		    margin-bottom: 0px;
		    margin-top: 0px;
	    }
	}

	.social-nav {
		text-align: center;
		margin-top: 25px;
		font-size: 22px;

		a {
			margin: 0 10px;
			text-decoration: none;
		}

		@include breakpoint(tablet) {
			text-align: right;
		}
	}

	.info {
		text-align: center;

		@include breakpoint(tablet) {
			text-align: left;
		}
	}

	.brand {
		overflow: hidden;
		text-align: center;

		a {
			background: url("#{$image-path}/logo-loodon.png") no-repeat top right;
			text-decoration: none;
			white-space: nowrap;
			display: block;
			clear: both;
			padding: 0 25px 0 0;
		}

		@include breakpoint(tablet) {
			text-align: right;
			float: right;

			a {
				text-align: left;
				float: right;
			}
		}
	}

}

/**
 * Nav Styles
 *
 * 	_Navitation
 * 	_Dropdown
 * 	_Mobile Nav
 *
 */

	#mobile-nav {
		font-family: $font-family-sans-serif;
		background: #555557;
		top: $header-height-mobile;
		visibility: hidden;
		color: $text-color;
		max-width: 300px;
		overflow: hidden;
		position: fixed;
		font-size: 1.3em;
		height: 100%;
		z-index: 500;
		right: -70%;
		width: 70%;

		@include transition(all .3s ease);

		.container-wrap {
			position: absolute;
			overflow: auto;
			width: 100%;
			bottom: 0;
			top: 0;
		}

		.container > ul > li {
			border-bottom: 1px solid #fff;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		li {
			position: relative;
			padding: 0;
			margin: 0;
		}

		a {
			text-decoration: none;
			padding: 15px 25px;
			color: $text-color;
			display: block;
			color: #fff;

			@include transition(all .3s ease);

			&:hover {
				color: $link-hover-color;
			}
		}

		.dropdown-menu {
			background: darken(#555557, 5%);
			position: relative;
			font-size: .9em;
			bottom: auto;
			border: none;
			float: none;
			width: 100%;
			z-index: 0;
			top: auto;

			li {
				border: none;
			}

			a {
				padding-left: 40px;
			}
		}
 	}

	// Nav open
	body.nav-open {
		#mobile-nav {
			visibility: visible;
			display: block;
			right: 0;
		}

		#page:before {
			visibility: visible;
			position: absolute;
			background: #000;
			height: 100%;
			content: "";
			width: 100%;
			opacity: .6;
			right: 0;
			top: 0;
		}

		#page-header .nav-button {
			color: $brand-secondary;
			@include icon($fa-var-times);
		}

		@include breakpoint(tablet) {
			#mobile-nav-bg {
				visibility: hidden;
			}

			#page:before {
				visibility: hidden;
			}

			#page-header .nav-button {
				display: none;
			}
		}
	}

/**
 * Variables
 */

/*
 * ================================
 *	_Paths
 * ================================
 */

	$font-path: 	"../fonts";
	$vendor-path: 	"../vendor";
	$image-path: 	"../images";

	$slick-loader-path: "../vendor/slick-carousel/slick/";
	$slick-font-path: "../vendor/slick-carousel/slick/fonts/";
	$fa-font-path: 	'../vendor/@fortawesome/fontawesome-free/webfonts';
	$fa-css-prefix: 'icon';

/*
 * ================================
 *	_Sizes
 * ================================
 */

	// Screen widths
	$screen-xs-min: 480px;
	$screen-sm-min: 768px;
	$screen-md-min: 992px;
	$screen-lg-min: 1200px;
	$screen-min: 290px;
	$screen-max: 970px;

	// Layout sizes
	$header-height-mobile: 55px;
	$footer-height: 4px;
	$sidebar-width: 25%;
	$content-width: 75%;
	$content-height-min: 300px;
	$breadcrumbs-height: 26px;

	// Padding
	$padding-base-vertical: 5px;
	$padding-base-horizontal: 5px;

/*
 * ================================
 *	_Colors
 * ================================
 */

	$brand-primary		: #ffffff;
	$brand-secondary	: #000000;
	$brand-highlight	: #c56b2a;
	$brand-banner		: #1b2956;
	$brand-gray			: #5a5b5d;

	$brand-default		: #70b333;
	$brand-primary		: #dcc49c;
	$brand-success		: #70b333;
	$brand-info			: #5bc0de;
	$brand-warning		: #f0ad4e;
	$brand-danger		: #a50303;
	$brand-border		: #939598;

	$gray-base			: #000;
	$gray-darker		: lighten($gray-base, 13.5%); // #222
	$gray-dark			: lighten($gray-base, 20%);   // #333
	$gray				: lighten($gray-base, 33.5%); // #555
	$gray-light			: lighten($gray-base, 46.7%); // #777
	$gray-lighter		: lighten($gray-base, 93.5%); // #eee

	$background-color	: #fff;
	$header-color		: #fff;
	$footer-color		: #000;
	$sidebar-color		: #fff;

	// Text
	$text-color			: $gray-base;
	$text-color-dark	: $gray-base;
	$link-color			: darken(#c56b2a, 5%);
	$link-hover-color	: #c56b2a;

	$header-text-color	: $gray-base;
	$footer-text-color	: #ffffff;
	$header-link-color	: $gray-base;
	$footer-link-color	: #ffffff;
	$footer-link-hover-color: $gray-base;

/*
 * ================================
 *	_Forms
 * ================================
 */

	$input-bg:				#fefefe;
	$input-bg-active:		darken($input-bg, 2%);
	$input-bg-disabled:		$gray-lighter;

	$input-error: 			#a50303;
	$input-color:			#4d4d4d;
	$input-border:			#ccc;
	$input-border-active:	#86b7fe;
	$input-border-radius:	.25rem;
	$input-height-base: 	15px;
	$input-color-placeholder: $gray-light;

/*
 * ================================
 *	_Fonts
 * ================================
 */

	// Sizes
	$font-size-base: 16px;
	$line-height-base: 22px;

	// Fonts
	$font-family-sans-serif		: "Helvetica Neue", helvetica, arial, sans-serif;
	$font-family-serif			: Georgia, "Times New Roman", Times, serif;
	$font-family-monospace		: Menlo, Monaco, Consolas, "Courier New", monospace;
	$font-family-base			: $font-family-serif;
	$font-family-secondary		: $font-family-serif;

/**
 * Button Styles
 */

input.btn,
a.btn,
.btn {

	color: choose-contrast-color($brand-highlight);
	border: 2px solid darken($brand-highlight, 10%);
	border-radius: $input-border-radius;
	background-color: $brand-highlight;
	vertical-align: middle;
	display: inline-block;
	text-decoration: none;
	font-weight: normal;
	padding: .3rem 1rem;
	position:relative;
	margin-bottom: 0;
	line-height: 1.5;
	cursor: pointer;
	outline: 0;

	&:hover,
	&:focus {
		background-color: lighten($brand-highlight, 5%);
		text-decoration: none;
	}

	&:focus {
		box-shadow: 0 0 0 .15rem darken($brand-highlight, 10%);
	}

	&.disabled,
	&[disabled] {
		text-shadow:0 1px 0 #f7f6f3;
		background-color: #bab9b9;
		border-color:#a8a8a8;
		font-weight:bold;
		box-shadow: none;
		cursor: default;
		top:0;
	}

	&.btn-sm {
		padding: .65rem .8rem;
		line-height: 1em;
		font-size: .8em;
	}

	&.btn-lg {
		padding: 20px 30px;
		line-height: 1em;
		font-size: 1em;
	}

	&.btn-link {
		background: transparent;
		color: $link-color;
		box-shadow: none;
		padding-right: 0;
		font-size: 1.1em;
		padding-left: 0;
		border: none;

		&:active {
			bottom: auto;
		}

		&:focus,
		&:hover {
			text-decoration: underline;
			color: $link-hover-color;
		}
	}

	&.btn-action {
		background: transparent;
		border: 1px solid #000;
		text-transform: uppercase;
		text-decoration: none;
		border-radius: 50px;
		padding: 10px 15px;
		color: $text-color;
		font-size: .9em;

		&:hover,
		&:focus {
			color: #fff !important;
			background: #000;
		}

		&:focus {
			box-shadow: 0 0 0 .15rem lighten(#000, 35%);
		}
	}

	&.btn-patreon {
		text-transform: uppercase;
		background: transparent;
		border: 1px solid #fff;
		border-radius: 20px;
		font-size: .7em;
		color: #fff;

		&:focus {
			box-shadow: 0 0 0 .15rem darken(#fff, 30%);
		}
	}

	&.btn-listen {
		background: #fff url("#{$image-path}/icon-headphones.png") no-repeat 5px 30%;
		padding: .45rem 1rem .45rem 70px;
		text-transform: uppercase;
		text-decoration: none;
		border-radius: 20px;
		color: $text-color;
		font-weight: bold;
		line-height: 30px;
		font-size: .8em;
		border: none;

		&:hover,
		&:focus {
			background-color: darken(#fff, 15%);
			text-decoration: none;
		}

		&:focus {
			box-shadow: 0 0 0 .15rem darken(#fff, 30%);
		}
	}

}
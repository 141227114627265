.product {
  display: flex;
  flex-direction: column;

  .woocommerce-product-gallery {
    overflow: hidden;
    padding-right: 40px;

    .woocommerce-product-gallery__trigger {
      display: none;
    }

    .flex-viewport {
      // Need to have imgs scale based on sizing
      margin-top: 40px;
    }

    ol {
      display: flex;
      flex-flow: row wrap;
      list-style-type: none;
      padding: 0px;
      margin: 0px;
    }
  }

  .summary {
    margin-top: 40px;
  }

  .variations {
    border: none !important;

    tr {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
    }

    .pa_size {
      margin: 0px;
    }

    .label,
    .value {
      margin: 0px;
      border: none;
    }
  }

  .woocommerce-tabs {
    display: none;
  }

  .woocommerce-product-details__short-description p:first-of-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .quantity {
    margin-right: 18px;
  }

  .label label {
    margin-bottom: 10px !important;
  }

  .single_add_to_cart_button {
    background-color: #a46497 !important;
    color: #ffffff !important;
    border: none !important;
    height: 40px !important;
  }

  .woocommerce-Price-amount {
    font-size: 20px;
    color: #77a464;
  }

  .woocommerce-variation-add-to-cart {
    display: flex;
  }

  #wcpay-payment-request-button-separator {
    display: none !important;
  }

  .GooglePayButton,
  .GooglePayButton--dark {
    visibility: hidden !important;
  }
}

@media all and (min-width: 780px) {
  .product {
    display: flex;
    flex-flow: row wrap;

    .woocommerce-product-gallery {
      margin-top: 40px;
      flex: 1;
    }

    .summary {
      margin-top: 40px;
      flex: 1;
    }

    .woocommerce-product-details__short-description p:first-of-type {
      flex-direction: row;
      justify-content: space-between;
    }

    .GooglePayButton,
    .GooglePayButton--dark {
      visibility: hidden !important;
    }
  }
}

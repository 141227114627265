/**
 * Banner Styles
 */

#page-banner {

	font-family: $font-family-sans-serif;
	background: $brand-banner;
	overflow: hidden;

	.inside {
		padding: 0 30px;

		@include breakpoint(tablet) {
			padding: 0;
		}
	}

	.banner-content {
		padding: 25px 0;
		overflow: hidden;
		margin: 0 auto;

		@include breakpoint(tablet) {
			justify-content: space-between;
			flex-wrap: nowrap;
			max-width: 700px;
			display: flex;
		}

		.logo {
			position: relative;
			flex-shrink: 0;
			padding: 0 20px;

			.img {
				background: url("#{$image-path}/logo2_revised.png") no-repeat top right;
				box-shadow: 0 12px 12px rgba(0,0,0, .6);
				background-size: cover;
				box-sizing: border-box;
				margin: 0 auto;
				height: 220px;
				width: 220px;

				@include breakpoint(tablet) {
					margin: 0;
				}
			}
		}

		.content {
			margin-top: 45px;

			@include breakpoint(tablet) {
				margin-top: 0;
			}

			h1,h2,h3,h4,h5,h6 {
				font-weight: 400;
				font-size: 1.6em;
				line-height: 1.4;
				color: #fff;
				padding: 0;
				margin: 0;
			}

			.btn-group {
				margin-top: 20px;

				.btn + .btn {
					margin-left: 15px;
				}
			}
		}
	}

}
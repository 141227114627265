/**
 * Body Styles
 */

#page-body {
	position: relative;
	font-size: 100%;
	clear: both;

	#page-content.wide {
		.section-content {
			border-right: none;
			width: 100%;

			@include breakpoint(tablet) {
				padding: 0;
			}
		}

		.section-sidebar {
			display: none;
		}

		.content-inside,
		.content-wrap {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.section {
		#post-type-post &:first-child {
			> .inside {
				border-top: none;
			}
		}
	}

	.section-main {
		.heading,
		.page-heading {
			font-size: 1.75em;
			color: $brand-gray;
			margin-bottom: 15px;

			h1,h2,h3,h4,h5,h6 {
				font-family: $font-family-secondary;
				font-weight: 400;
				font-size: 1em;
				padding: 0;
				margin: 0;
			}
		}

		.content-wrap {
			@include clearfix();
		}

		#post-type-post & .content-wrap {
			max-width: 800px;
			margin: 0 auto;
		}
	}

	.section-content {
		@include breakpoint(tablet) {
			border-right: 1px solid $brand-border;
			min-height: $content-height-min;
			width: $content-width;
			float: left;

			#homepage & {
				width: $content-width - 8%;
			}
		}

		.content-inside {
			@include clearfix();

			@include breakpoint(tablet) {
				padding: 0 25px 0 0;
			}
		}
	}

	.section-sidebar {
		border-top: 1px solid $brand-border;
		padding: 45px 0;
		margin-top: 45px;
		@include clearfix();

		@include breakpoint(tablet) {
			border-left: 1px solid $brand-border;
			width: $sidebar-width;
			position: relative;
			border-top: none;
			padding-top: 0;
			margin-top: 0;
			float: right;
			left: -1px;

			#homepage & {
				width: $sidebar-width + 8%;
			}

			.sidebar-inside {
				padding-left: 25px;
			}
		}

		.post-article {
			.post-heading {
				padding-bottom: 10px;

				h1,h2,h3,h4,h5,h6 {
					font-size: 1.6em;
				}
			}
		}

		.sidebar-widgets + .section {
			margin-top: 25px;
		}

		.heading,
		.widget-heading {
			margin-bottom: 15px;
			font-size: 1.75em;
			color: #5a5b5d;

			h1,h2,h3,h4,h5,h6 {
				font-family: $font-family-secondary;
				font-weight: 400;
				font-size: 1em;
				padding: 0;
				margin: 0;
			}
		}

		.widget + .widget {
			overflow: hidden;
			margin-top: 40px;
		}
	}

	.section-featured-slider {
		> .inside {
			padding-bottom: 15px;
			padding-top: 15px;
		}
	}

	.section-support {
		overflow: hidden;

		.col {
			position: relative;
			min-height: 258px;
			height: 100%;

			@include breakpoint(tablet) {
				float: left;
				width: 48%;
			}

			& + .col {
				padding-top: 45px;

				@include breakpoint(tablet) {
					float: right;
				}

				div + div {
					margin-top: 10px;
				}
			}
		}

		p {
			margin: 0;
			padding: 0;
		}

		.heading {
			font-family: $font-family-sans-serif;
			text-transform: uppercase;
			color: #ff424d;
			font-size: 1.5em;
		}

		.flex-hz {
			& > div + div {
				margin-top: 20px;
			}

			@include breakpoint(tablet) {
				justify-content: center;
				max-width: 600px;
				padding: 10px 0;
				margin: 0 auto;
				display: flex;
				flex: 1 1 0;

				& > div {
					margin:  auto;
				}

				& > div + div {
					margin-top: 0;
				}

				.content {
					text-align: center;
				}
			}
		}
	}

	.section-latest-podcasts {
		> .inside {
			box-sizing: border-box;
			padding-bottom: 15px;
			padding-top: 15px;
		}
	}
}

/**
 * Global Styles
 */

/*
 * ================================
 * 	_Global
 * ================================
 */

    html {
        background: $background-color;
        box-sizing: border-box;
        height: 100%;
    }

    body {
        background: $background-color;
        min-width: $screen-min;
        height: 100%;
        padding: 0;
        margin: 0;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    .inside {
        min-width: $screen-min;
        max-width: $screen-max;
        position: relative;
        margin: 0 auto;
        padding: 0;
        width: 96%;

        @include clearfix();
    }

    .logo-patreon {
        background-image: url("#{$image-path}/logo-patreon-sm-red.png");
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        height: 16px;
        width: 124px;
    }

    .img-container {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .container-row {
        margin: 0 auto 25px auto;
        max-width: 800px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .error-content {
        font-size: 1.25em;
        margin: 50px 0;

        h1,h2,h3,h4,h5,h6 {
            color: #5a5b5d;
            font-size: 2em;
        }
    }

	.section {
		> .inside {
			border-top: 1px solid $brand-border;
			box-sizing: border-box;
			padding: 20px 30px;
		}
	}

    .section-sponsor {
        overflow: hidden;
        text-align: center;

        &.vt {
            min-height: 500px;

            .placeholder {
                min-height: 500px;
                max-width: 250px;
                margin: 0 auto;
            }
        }

        &.hz {
            min-height: 80px;

            .placeholder {
                max-width: 610px;
                min-height: 80px;
            }
        }

        img {
            max-width: 100%;
        }

        a {
            display: block;
        }

        a + a {
            margin-top: 40px;
        }

        .placeholder {
            background-position: center;
            background-repeat: no-repeat;
            background-color: $gray-lighter;
            background-size: contain;
            position: relative;
            text-align: center;
            overflow: hidden;
            margin: 0 auto;

            a {
                position: absolute;
                display: block;
                height: 100%;
                width: 100%;
                bottom: 0;
                right: 0;
                left:0;
                top: 0;
            }

            & + .placeholder {
                margin-top: 40px;
            }
        }

        & + .section-sponsor {
            margin-top: 40px;
        }
    }

    #page-wrap {
        min-height: 100%;
        position: relative;
    }

    #page {
        position: relative;
        min-height: 100%;
        width: 100%;

        @include breakpoint(tablet) {
            height: 100%;
        }

        &:before {
            background-position: 50% 200px;
            background-attachment: fixed;
            background-repeat: no-repeat;
            position: absolute;
            visibility: hidden;
            background: #fff;
            z-index: 100;
            height: 100%;
            width: 100%;
            content: "";
            opacity: 0;
            left: 0;
            top: 0;
            @include transition(all .3s linear);
        }
    }

/*
 * ================================
 * 	_Other
 * ================================
 */

    // Helper Classes
    .group { @include clearfix(); }
    .visible { display: block; }
    .hidden { display: none; }

    // Selection Color
    ::-moz-selection {background: $brand-secondary; color: #fff; text-shadow: -1px 1px 0 $text-color-dark;}
    ::selection {background: $brand-secondary; color: #fff; text-shadow: -1px 1px 0 $text-color-dark;}
    a::-moz-selection {background: $link-color; color: #fff;}
    a::selection {background: $link-color; color: #fff;}
    img::-moz-selection {background: transparent;}
    img::selection {background: transparent;}

    /*
 * ================================
 * 	About Chuck page
 * ================================
 */

 .about-chuck {
  display: flex;
  flex-direction: column;
}

#input_2_1_3, #input_2_2, #input_2_3	{
  background-color: #f7f7f7;
  border: none;
  box-shadow: none;
  margin-bottom: 0px;
  font-family: sans-serif;
  font-weight: bold;
  padding: 10px;
  max-width: none;
  width: 100%;
}

#input_2_1_3::placeholder, #input_2_2::placeholder, #input_2_3::placeholder {
  color: #333;
}

.about-chuck h2 {
  font-weight: 400;
  font-size: 25px;
  font-family: Georgia,Times New Roman,Times,serif;
  color: #666666;
}

#gform_submit_button_2 {
  border: none;
  background-color: #f47920;
  color: white;
  width: 100px;
  text-justify: left;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left; 
  border-radius: 0px;
  margin-bottom: 50px;
  padding: 10px
}

@media all and (min-width: 750px) {
  #gform_submit_button_2 {
    
    width: 20%;
  }
  
  #input_2_1_3, #input_2_2, #input_2_3 {
    width: 60%;
  }
}